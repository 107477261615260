import { useEffect, useState } from "react";
import _ from "lodash";
import { BrowserRouter as Router, Switch, Route/*, Link*/, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.scss";

import MainMenu from "./components/mainMenu";
// import Category from "./components/category";
import Subcategory from "./components/subcategory";
import VideoTest from "./components/videotest";

global._ = _;

export default function App() {

    const [firstLoad, setFirstLoad] = useState(true);
    const [env, setEnv] = useState('DEV');

    useEffect(() => {
        setFirstLoad(false);
        document.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });
        (async () => {
            try{
                await fetch('http://localhost:9090/handshake', {mode: 'no-cors'});
                setEnv('PROD');
            }catch(err){
                setEnv('DEV');
                //console.error(err);
            }
        })();
    }, []);

    return (

        <Router>

            <Switch>

                <Route path={"/videotest"}>
                    <VideoTest env={env} />
                </Route>

                <Route path={"/category/:category_id/:subcategory_id"}>
                    <Subcategory env={env} />
                </Route>

                <Route path={"/category/:category_id"}>

                    {/* <Category /> */}
                    <MainMenu env={env} />

                </Route>

                <Route path={"/"}>
                    {firstLoad ?
                        <Redirect to='/?play=true' />
                    :
                        <MainMenu env={env} />
                    }

                </Route>

            </Switch>

        </Router>

    );
}
