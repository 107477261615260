import { useEffect, useRef } from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default function Video({options, onReady, autoPlay, loop}) {

	const video = useRef(null);
	const player = useRef(null);

	useEffect(() => {
		if(!player.current){
			if(!video.current){ return; }

			player.current = videojs(video.current, options, () => {
				//videojs.log('player ready');
				onReady && onReady(player.current);
			});
		}else{
			//update existing player
		}
	}, [options, video, onReady]);

	/*useEffect(() => {
		return () => {
			if(player.current){
				player.current.dispose();
				player.current = null;
			}
		}
	}, [player]);*/

	return(
		<div data-vjs-player>
			<video ref={video} muted autoPlay={autoPlay} loop={loop} className="video-js vjs-big-play-centered" />
		</div>
	)
}