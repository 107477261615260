import { useEffect, useRef, useState } from "react";
import Video from "./Video";

export default function VideoTest({env}) {

	const [player, setPlayer] = useState(null);
	const options = {
		autoplay: true,
		controls: false,
		responsive: true,
		fluid: true,
		sources: [
			{
				src: env === 'PROD' ? 'http://localhost:9090/video/Material_Processing.mp4' : 'https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/g-expansive-fleet/ORIGINAL/category/Earthwork.mp4',
				type: 'video/mp4'
			}
		]
	};
	
	const playerReady = (player) => {
		setPlayer(player);
		/*player.on('waiting', () => {
			console.log('player waiting');
		});*/

		player.on('dispose', () => {
			console.log('player dispose');
		});

		/*setTimeout(() => {
			player.dispose();
		}, 4000);*/
	}

	const dispose = () => {
		player.dispose();
	}

	return(
		<>
			<h1>test</h1>
			<button onClick={dispose}>Dispose</button>
			<Video options={options} onReady={playerReady} />
		</>
	)
}